import { dataConstants } from '../constants';


export function data(state = {}, action) {
  switch (action.type) {
    case dataConstants.DATA_RESET:
      return {};
    case dataConstants.UPDATE_MEALS_REQUEST:
      return {
        ...state,
        meals: {
          isPending: true
        }
      };
    case dataConstants.UPDATE_STORE_HOURS_SUCCESS:
      return {
        ...state,
        storeHours: action.storeHours
      };
    case dataConstants.UPDATE_OLD_NUM_VOUCHERS_SUCCESS:
      return {
        ...state,
        numVouchers: action.numVouchers
      };
    case dataConstants.UPDATE_NUM_CLAIMED_SUCCESS:
      return {
        ...state,
        numClaimed: action.numClaimed
      };
    case dataConstants.UPDATE_NUM_VOUCHERS_CLAIMED_SUCCESS:
      return {
        ...state,
        numClaimedVouchers: action.numClaimed
      };
    case dataConstants.UPDATE_NUM_COMPLETED_VOUCHERS_SUCCESS:
      return {
        ...state,
        numCompletedVouchers: action.numCompleted
      };
    case dataConstants.UPDATE_NUM_COMPLETED_SUCCESS:
      return {
        ...state,
        numCompleted: action.numCompleted
      };
    case dataConstants.UPDATE_NUM_HOSTED_SUCCESS:
      return {
        ...state,
        numHosted: action.numHosted
      };
    case dataConstants.UPDATE_SELECTED_TEMPLATES_SUCCESS:
      return {
        ...state,
        selectedTemplates: action.selectedTemplates
      };
    case dataConstants.UPDATE_SELECTED_SAVED_VOUCHERS_SUCCESS:
      return {
        ...state,
        selectedSavedVouchers: action.selectedSavedVouchers
      };
    case dataConstants.UPDATE_SELECTED_HOSTED_MEALS_SUCCESS:
      return {
        ...state,
        selectedHostedMeals: action.selectedHostedMeals
      };
  case dataConstants.TOGGLE_PICKED_UP_SUCCESS:
      return {
        ...state,
        togglePickedUp: {
          isLoaded: true,
          isPending: false,
          order: action.order,
          action: action.action
        }
      };
    case dataConstants.TOGGLE_PICKED_UP_VOUCHERS_SUCCESS:
      return {
        ...state,
        togglePickedUpVouchers: {
          isLoaded: true,
          isPending: false,
          order: action.order,
          action: action.action
        }
      };
    case dataConstants.UPDATE_MEALS_SUCCESS:
      return {
        ...state,
        meals: {
          isLoaded: true,
          isPending: false,
          data: action.data
        }
      };
    case dataConstants.UPDATE_MEALS_FAILURE:
      return {
        ...state,
        meals: {}
      };
    case dataConstants.UPDATE_MEALS_FOR_SALE_REQUEST:
      return {
        ...state,
        mealsForSale: {
          isPending: true
        }
      };
    case dataConstants.UPDATE_MEALS_FOR_SALE_SUCCESS:
      return {
        ...state,
        mealsForSale: {
          isLoaded: true,
          isPending: false,
          data: action.data
        }
      };
    case dataConstants.UPDATE_MEALS_FOR_SALE_FAILURE:
      return {
        ...state,
        mealsForSale: {}
      };
    case dataConstants.UPDATE_DASHBOARD_INDEX_SUCCESS:
      return {
        ...state,
        dashboardIndex: action.index
      };
    case dataConstants.ACTIVATED_VOUCHERS_SUCCESS:
      return {
        ...state,
        activatedVouchers: {
          isLoaded: true,
          isPending: false,
          isError: false,
          activeVouchers: action.activeVouchers,
          deactiveVouchers: action.deactiveVouchers,
        }
      };
    case dataConstants.ACTIVATED_VOUCHERS_FAILURE:
      return {
        ...state,
        activatedVouchers: {
          ...state.activatedVouchers,
          isLoaded: true,
          isPending: false,
          isError: true,
        }
      };
    case dataConstants.ACTIVATED_VOUCHERS_REQUEST:
      return {
        ...state,
        activatedVouchers: {
          ...state.activatedVouchers,
          isLoaded: false,
          isPending: true,
          isError: false,
        }
      };
    case dataConstants.UPDATE_PURCHASED_VOUCHERS_SUCCESS:
      return {
        ...state,
        purchasedVouchers: {
          isLoaded: true,
          isPending: false,
          data: action.data,
          total: action.total,
          orders: action.orders,
          allPurchased: action.allPurchased,
        }
      };
    case dataConstants.UPDATE_PURCHASED_SUCCESS:
      return {
        ...state,
        purchasedMeals: {
          isLoaded: true,
          isPending: false,
          data: action.data,
          total: action.total,
          orders: action.orders,
          allPurchased: action.allPurchased,
        }
      };
    case dataConstants.UPDATE_PURCHASED_FAILURE:
      return {
        ...state,
        purchasedMeals: {}
      };
    case dataConstants.UPDATE_EXPIRED_MEALS_SUCCESS:
      return {
        ...state,
        expiredMeals: {
          isLoaded: true,
          isPending: false,
          data: action.expired,
          allExpired: action.allExpired,
        }
      };
    case dataConstants.UPDATE_EXPIRED_MEALS_FAILURE:
      return {
        ...state,
        expiredMeals: {}
      };
    case dataConstants.UPDATE_TODAY_SUCCESS:
      return {
        ...state,
        today: action.today
      };
    case dataConstants.UPDATE_PURCHASED_NOT_COMPLETED_SUCCESS:
      return {
        ...state,
        purchasedNotCompleted: {
          isLoaded: true,
          isPending: false,
          data: action.data,
        }
    };
    case dataConstants.UPDATE_PURCHASED_NOT_COMPLETED_VOUCHERS_SUCCESS:
      return {
        ...state,
        purchasedNotCompletedVouchers: {
          isLoaded: true,
          isPending: false,
          data: action.data,
        }
    };
    case dataConstants.UPDATE_PURCHASED_NOT_COMPLETED_FAILURE:
      return {
        ...state,
        purchasedNotCompleted: {}
    };
    case dataConstants.UPDATE_ORDER_STATS_SUCCESS:
      return {
        ...state,
        orderStats: {
          ...state.orderStats,
          isLoaded: true,
          isPending: false,
          lineChartData: action.lineChartData,
          pieData: action.pieData,
          orderTitle: action.orderTitle,
          usersData: action.usersData,
          endDate: action.endDate,
          startDate: action.startDate,
          recentOrders: action.recentOrders,
          allRecentOrders: action.allRecentOrders,
          recentOrdersTotal: action.recentOrdersTotal,
          range: action.range,
        }
    };
    case dataConstants.UPDATE_START_DATE_SUCCESS:
      return {
        ...state,
        orderStats: {
          ...state.orderStats,
          isLoaded: true,
          isPending: false,
          startDate: action.startDate,
          range: action.range
        }
    };
    case dataConstants.UPDATE_ORDER_STATS_FAILURE:
      return {
        ...state,
        orderStats: {}
    };
    case dataConstants.UPDATE_HOSTED_MEALS_SUCCESS:
      return {
        ...state,
        hostedMeals: {
          isLoaded: true,
          isPending: false,
          data: action.data,
          allHostedMeals: action.allHostedMeals
        }
    };
    case dataConstants.UPDATE_COMPLETED_VOUCHERS_SUCCESS:
      return {
        ...state,
        completedVouchers: {
          isLoaded: true,
          isPending: false,
          data: action.data,
          allSales: action.allCompleted,
        }
      };
    case dataConstants.UPDATE_COMPLETED_VOUCHERS_FAILURE:
      return {
        ...state,
        completedVouchers: {}
      };
    case dataConstants.UPDATE_COMPLETED_ORDERS_SUCCESS:
      return {
        ...state,
        completedOrders: {
          isLoaded: true,
          isPending: false,
          data: action.data,
          allSales: action.allCompleted,
        }
      };
    case dataConstants.UPDATE_COMPLETED_ORDERS_FAILURE:
      return {
        ...state,
        completedOrders: {}
      };
    case dataConstants.UPDATE_LOCATIONS_REQUEST:
      return {
        ...state,
        locations: {
          isPending: true
        }
      };
    case dataConstants.UPDATE_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: {
          isLoaded: true,
          isPending: false,
          data: action.data
        }
      };
    case dataConstants.UPDATE_LOCATIONS_FAILURE:
      return {
        ...state,
        locations: {}
      };
    case dataConstants.UPDATE_INVENTORY_REQUEST:
      return {
        ...state,
        inventory: {
          isPending: true
        }
      };
    case dataConstants.UPDATE_INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: {
          isLoaded: true,
          isPending: false,
          data: action.data
        }
      };
    case dataConstants.UPDATE_INVENTORY_FAILURE:
      return {
        ...state,
        inventory: {}
      };
    case dataConstants.UPDATE_SAVED_VOUCHERS_SUCCESS:
      return {
        ...state,
        savedVouchers: {
          data: action.data
        }
      };
    case dataConstants.UPDATE_SAVED_VOUCHERS_FAILURE:
      return {
        ...state,
        savedVouchers: {}
      };

    case dataConstants.UPDATE_PICKEDUP_REQUEST:
      return {
        ...state,
        pickedUp: {
          isPending: true
        }
      };
    case dataConstants.UPDATE_PICKEDUP_SUCCESS:
      return {
        ...state,
        pickedUp: {
          isLoaded: true,
          isPending: false,
          data: action.data
        }
      };
    case dataConstants.UPDATE_PICKEDUP_FAILURE:
      return {
        ...state,
        pickedUp: {}
      };
    default:
      return state
  }
}
