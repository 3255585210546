export const dataConstants = {

    DATA_RESET: 'DATA_UPDATE_DATA_RESET',

    UPDATE_MEALS_REQUEST: 'DATA_UPDATE_MEALS_REQUEST',
    UPDATE_MEALS_SUCCESS: 'DATA_UPDATE_MEALS_SUCCESS',
    UPDATE_MEALS_FAILURE: 'DATA_UPDATE_MEALS_FAILURE',

    UPDATE_HOSTED_MEALS_SUCCESS: 'DATA_UPDATE_HOSTED_MEALS_SUCCESS',

    UPDATE_TODAY_SUCCESS: 'DATA_UPDATE_TODAY_SUCCESS',

    UPDATE_START_DATE_SUCCESS: 'DATA_UPDATE_START_DATE_SUCCESS',

    TOGGLE_PICKED_UP_SUCCESS: 'DATA_TOGGLE_PICKED_UP_SUCCESS',

    UPDATE_STORE_HOURS_SUCCESS: 'DATA_STORE_HOURS_SUCCESS',

    // Vouchers
    UPDATE_PURCHASED_VOUCHERS_SUCCESS: 'DATA_UPDATE_PURCHASED_VOUCHERS_SUCCESS',
    UPDATE_COMPLETED_VOUCHERS_FAILURE: 'DATA_UPDATE_COMPLETED_VOUCHERS_FAILURE',
    UPDATE_COMPLETED_VOUCHERS_SUCCESS: 'DATA_UPDATE_COMPLETED_VOUCHERS_SUCCESS',
    UPDATE_NUM_COMPLETED_VOUCHERS_SUCCESS: 'DATA_UPDATE_NUM_COMPLETED_VOUCHERS_SUCCESS',
    UPDATE_NUM_VOUCHERS_CLAIMED_SUCCESS: 'DATA_UPDATE_NUM_VOUCHERS_CLAIMED_SUCCESS',
    TOGGLE_PICKED_UP_VOUCHERS_SUCCESS: 'DATA_TOGGLE_PICKED_UP_VOUCHERS_SUCCESS',
    UPDATE_PURCHASED_NOT_COMPLETED_VOUCHERS_SUCCESS: 'DATA_UPDATE_PURCHASED_NOT_COMPLETED_VOUCHERS_SUCCESS',
    TOGGLE_PICKED_UP_VOUCHERS_FAILURE: 'DATA_TOGGLE_PICKED_UP_VOUCHERS_FAILURE',


    UPDATE_OLD_NUM_VOUCHERS_SUCCESS: 'DATA_UPDATE_OLD_NUM_VOUCHERS_SUCCESS',

    UPDATE_SELECTED_TEMPLATES_SUCCESS: 'DATA_UPDATE_SELECTED_TEMPLATES_SUCCESS',

    UPDATE_SAVED_VOUCHERS_SUCCESS: 'DATA_UPDATE_SAVED_VOUCHERS_SUCCESS',
    UPDATE_SAVED_VOUCHERS_FAILURE: 'DATA_UPDATE_SAVED_VOUCHERS_FAILURE',

    UPDATE_SELECTED_SAVED_VOUCHERS_SUCCESS: 'DATA_UPDATE_SELECTED_SAVED_VOUCHERS_SUCCESS',

    UPDATE_NUM_CLAIMED_SUCCESS: 'DATA_UPDATE_NUM_CLAIMED_SUCCESS',
    UPDATE_NUM_COMPLETED_SUCCESS: 'DATA_UPDATE_NUM_COMPLETED_SUCCESS',
    UPDATE_NUM_HOSTED_SUCCESS: 'DATA_UPDATE_NUM_HOSTED_SUCCESS',

    UPDATE_SELECTED_HOSTED_MEALS_SUCCESS: 'DATA_UPDATE_SELECTED_HOSTED_MEALS_SUCCESS',

    UPDATE_ORDER_STATS_SUCCESS: 'DATA_UPDATE_ORDER_STATS_SUCCESS',
    UPDATE_ORDER_STATS_FAILURE: 'DATA_UPDATE_ORDER_STATS_FAILURE',

    UPDATE_EXPIRED_MEALS_SUCCESS: 'DATA_UPDATE_EXPIRED_MEALS_SUCCESS',
    UPDATE_EXPIRED_MEALS_FAILURE: 'DATA_UPDATE_EXPIRED_MEALS_FAILURE',

    UPDATE_PURCHASED_NOT_COMPLETED_REQUEST: 'DATA_UPDATE_PURCHASED_NOT_COMPLETED_REQUEST',
    UPDATE_PURCHASED_NOT_COMPLETED_SUCCESS: 'DATA_UPDATE_PURCHASED_NOT_COMPLETED_SUCCESS',
    UPDATE_PURCHASED_NOT_COMPLETED_FAILURE: 'DATA_UPDATE_PURCHASED_NOT_COMPLETED_FAILURE',

    UPDATE_MEALS_FOR_SALE_REQUEST: 'DATA_UPDATE_MEALS_FOR_SALE_REQUEST',
    UPDATE_MEALS_FOR_SALE_SUCCESS: 'DATA_UPDATE_MEALS_FOR_SALE_SUCCESS',
    UPDATE_MEALS_FOR_SALE_FAILURE: 'DATA_UPDATE_MEALS_FOR_SALE_FAILURE',

    ACTIVATED_VOUCHERS_REQUEST: 'DATA_ACTIVATED_VOUCHERS_REQUEST',
    ACTIVATED_VOUCHERS_SUCCESS: 'DATA_ACTIVATED_VOUCHERS_SUCCESS',
    ACTIVATED_VOUCHERS_FAILURE: 'DATA_ACTIVATED_VOUCHERS_FAILURE',

    UPDATE_DASHBOARD_INDEX_SUCCESS: 'DATA_UPDATE_DASHBOARD_INDEX_SUCCESS',

    UPDATE_PURCHASED_REQUEST: 'DATA_UPDATE_PURCHASED_REQUEST',
    UPDATE_PURCHASED_SUCCESS: 'DATA_UPDATE_PURCHASED_SUCCESS',
    UPDATE_PURCHASED_FAILURE: 'DATA_UPDATE_PURCHASED_FAILURE',

    UPDATE_COMPLETED_ORDERS_REQUEST: 'DATA_UPDATE_COMPLETED_ORDERS_REQUEST',
    UPDATE_COMPLETED_ORDERS_SUCCESS: 'DATA_UPDATE_COMPLETED_ORDERS_SUCCESS',
    UPDATE_COMPLETED_ORDERS_FAILURE: 'DATA_UPDATE_COMPLETED_ORDERS_FAILURE',

    UPDATE_LOCATIONS_REQUEST: 'DATA_UPDATE_LOCATIONS_REQUEST',
    UPDATE_LOCATIONS_SUCCESS: 'DATA_UPDATE_LOCATIONS_SUCCESS',
    UPDATE_LOCATIONS_FAILURE: 'DATA_UPDATE_LOCATIONS_FAILURE',

    UPDATE_INVENTORY_REQUEST: 'DATA_UPDATE_INVENTORY_REQUEST',
    UPDATE_INVENTORY_SUCCESS: 'DATA_UPDATE_INVENTORY_SUCCESS',
    UPDATE_INVENTORY_FAILURE: 'DATA_UPDATE_INVENTORY_FAILURE',

    UPDATE_PICKEDUP_REQUEST: 'DATA_UPDATE_PICKEDUP_REQUEST',
    UPDATE_PICKEDUP_SUCCESS: 'DATA_UPDATE_PICKEDUP_SUCCESS',
    UPDATE_PICKEDUP_FAILURE: 'DATA_UPDATE_PICKEDUP_FAILURE',

};
