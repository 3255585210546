import { userConstants } from '../constants';

const initialState = {
  isLoginSuccess: false,
  isLoginPending: false,
  loginError: null,
  user: null,
  userData: null,
}

export function authentication(state = { initialState }, action) {
  switch (action.type) {
    case userConstants.LOGIN_RESET:
      return {
        ...state.human,
        human: state.human,
        isLoginSuccess: false,
      };
    case userConstants.LOGIN_REQUEST:
      return {
        ...state,
        isLoginPending: action.isLoginPending,
        user: action.user,
        isLoginSuccess: false,
        loginError: false,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        isLoginSuccess: action.isLoginSuccess,
        user: action.user,
        userData: action.userData,
        isLoginPending: false,
      };
    case userConstants.UPDATE_MODULES_SUCCESS:
      return {
        ...state,
        modules: {
          available: action.modules.available,
          loaded: action.modules.loaded,
        }
      };
    case userConstants.UPDATE_ORDER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        orderNotifications: {
          email: action.orderNotifications.email,
          fax: action.orderNotifications.fax,
          emailAddress: action.orderNotifications.emailAddress,
          faxNumber: action.orderNotifications.faxNumber,
        }
      };
    case userConstants.LOGIN_STATUS_REQUEST:
      return {
        ...state,
        isLoginPending: action.isLoginPending,
        isLoginSuccess: false,
        loginError: false,
      };
    case userConstants.LOGIN_STATUS_SUCCESS:
      return {
        ...state,
        isLoginSuccess: action.isLoginSuccess,
        isLoginPending: false,
        loginError: false,
      };
    case userConstants.LOGIN_FAILURE:
      return {
        ...state,
        loginError: action.loginError,
        isLoginPending: false,
      };
    case userConstants.ACKNOWLEDGE_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          profileDirections: false,
        }
      };
    case userConstants.SIGN_UP_RESET:
      return {
        ...state,
        isSignUpSuccess: false,
        businessName: null,
      };
    case userConstants.SIGN_UP_SUCCESS:
      return {
        ...state,
        isSignUpSuccess: true,
        email: action.email,
      };
    case userConstants.UPDATE_REQUEST:
      return {
        ...state,
        loggedIn: true,
        user: action.user,
        userData: action.userData,
      };
    case userConstants.UPDATE_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        userData: action.userData,
      };
    case userConstants.UPDATE_FAILURE:
      return {};
    case userConstants.UPDATE_CAPTCHA:
      return {
        ...state,
        human: action.human,
        signUp: action.signUp,
        latestAction: action.latestAction
      };
    case userConstants.UPDATE_IMAGE_FILES:
      return {
        ...state,
        logoFiles: action.logoFiles,
        backgroundImageFiles: action.backgroundImageFiles
      };
    case userConstants.CLEAR_LOGIN_SUCCESS:
      return {
        ...state,
        isLoginPending: false,
        isLoginSuccess: false,
        isSignUpSuccess: false,
      };
    case userConstants.PASSWORD_EDITING:
      return {
        ...state,
        passwordEditing: action.passEdit,
      };
    case userConstants.SUBMIT_STATUS_SUCCESS:
      return {
        ...state,
        submittedForReview: action.submitted,
      };
    case userConstants.NON_SENSITIVE_DATA_STATUS_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          business: action.businessData.business,
        }
      };
    case userConstants.UPDATE_AUTHENTICATED:
      return {
        ...state,
        authenticated: action.authenticated,
      };
    case userConstants.UPDATE_STRIPE_ID:
      return {
        ...state,
        stripeMerchantId: action.stripeMerchantId,
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state
  }
}
