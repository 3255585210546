export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGIN_RESET: 'USERS_LOGIN_RESET',
    LOGIN_STATUS_REQUEST: 'USERS_LOGIN_STATUS_REQUEST',
    LOGIN_STATUS_SUCCESS: 'USERS_LOGIN_STATUS_SUCCESS',

    UPDATE_REQUEST: 'USERS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    SIGN_UP_SUCCESS: 'USERS_SIGN_UP_SUCCESS',

    UPDATE_MODULES_SUCCESS: 'USERS_UPDATE_MODULES_SUCCESS',

    SUBMIT_STATUS_SUCCESS: 'USERS_SUBMIT_STATUS_SUCCESS',

    UPDATE_ORDER_NOTIFICATIONS_SUCCESS: 'USERS_UPDATE_ORDER_NOTIFICATIONS_SUCCESS',

    NON_SENSITIVE_DATA_STATUS_SUCCESS: 'USERS_NON_SENSITIVE_DATA_STATUS_SUCCESS',

    PASSWORD_EDITING: 'USERS_PASSWORD_EDITING',

    UPDATE_AUTHENTICATED: 'USERS_UPDATE_AUTHENTICATED',

    UPDATE_STRIPE_ID: 'USERS_UPDATE_STRIPE_ID',

    ACKNOWLEDGE_SUCCESS: 'USERS_ACKNOWLEDGE_SUCCESS',

    UPDATE_IMAGE_FILES: 'USERS_UPDATE_IMAGE_FILES',

    CLEAR_LOGIN_SUCCESS: 'USERS_CLEAR_LOGIN_SUCCESS',

    UPDATE_CAPTCHA: 'USERS_UPDATE_CAPTCHA',

    SIGN_UP_RESET: 'USERS_SIGN_UP_RESET',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE'
};
